<template>
  <div v-if="!isLoading">
    <HeaderPanel
      title="Promotion"
      :filter="filter"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      :hasSearch="false"
      :hasExport="true"
      @export="exportPromotionCustomer"
    />
    <p class="my-2 f-16">
      Total Customer : {{ stat.total_customer | numeral("0,0") }} ({{
        stat.percen_customer | numeral("0,0.00")
      }}%)
    </p>
    <p class="my-2 f-16">
      {{ handleDateRange }}
    </p>
    <StatPanel :column="statColumn" :data="stat" :isLoading="statLoading" />

    <div class="chart-bg">
      <b-container class="pt-3">
        <b-row class="chart-gap">
          <b-col cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="d-flex justify-content-between mb-3">
                <div class="chart-title-name">Top 10 Used Promotion</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight"
                      @click="exportExcel(2, 'promotion-use')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>

              <Chart
                id="promotion-chart"
                :chartData="chartData"
                text="Number Of Usage"
                class="mt-auto"
                :label="chartLabel"
                :hideLegend="false"
                v-if="!isChartLoading"
                type="bar"
              />
              <ChartLoading
                v-else
                :id="'loading-' + 'promotions'"
                type="bar"
              /> </b-card
          ></b-col>
          <b-col cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="d-flex justify-content-between mb-3">
                <div>
                  <div class="chart-title-name">Promotion Discount</div>
                  <p class="m-0 f-16">
                    Total Revenue :
                    {{ subTotal.total_revenue | numeral("0,0") }}
                  </p>
                  <p class="m-0 f-16">
                    Total Discount :
                    {{ subTotal.total_discount | numeral("0,0") }}
                  </p>
                </div>

                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <!-- @click="hadleChartFilter(chartFilter, chart)" -->
                    <span
                      class="text-highlight"
                      @click="exportExcel(3, 'promotion_revenue')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>
              <b-table
                fixed
                striped
                hover
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
                foot-clone
                no-footer-sorting
                @sort-changed="sortingTable"
                :sort-by.sync="filterTable.sortBy"
                no-local-sorting
                :sort-desc.sync="filterTable.sortDesc"
              >
                <template #cell(promotion_discount)="{ item }">
                  {{ item.promotion_discount | numeral("0,0") }}
                </template>
                <template v-slot:foot()="data">
                  <span v-if="subTotal[data.field.key]" class="text-center"
                    >{{ subTotal[data.field.key] | numeral("0,0.00") }}
                  </span>
                  <span v-else-if="data.field.key == 'promotion_use'">{{
                    subTotal.total_use | numeral("0,0")
                  }}</span>
                  <span v-else-if="data.field.key == 'promotion_revenue'">{{
                    subTotal.total_revenue | numeral("0,0")
                  }}</span>
                  <span v-else-if="data.field.key == 'promotion_discount'">{{
                    subTotal.total_discount | numeral("0,0")
                  }}</span>
                  <span v-else>
                    <div class="text-left px-2" v-if="data.field.text">
                      {{ data.field.text }}
                    </div>
                  </span>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleChangeTake"
                @pagination="pagination"
                :filter="filterTable"
                :rows="rows"
                :limit="2"
              /> </b-card
          ></b-col>
          <b-col cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="d-flex justify-content-between mb-3">
                <div class="chart-title-name">Promotion Discount</div>
                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <b-dropdown id="dropdown" right class="btn-mobile mr-2">
                    <template #button-content>
                      <span class="d-none d-md-inline-flex btn-text mr-1"
                        >Export
                      </span>
                    </template>
                    <b-dropdown-item
                      @click="exportExcel(4, 'promotion_branch')"
                    >
                      <span>
                        <font-awesome-icon
                          icon="file-export"
                          class="pointer mr-2"
                        />
                      </span>
                      <span>Export File</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="exportExcel(5, 'promotion_branch_pivot')"
                    >
                      <span>
                        <font-awesome-icon
                          icon="file-export"
                          class="pointer mr-2"
                        />
                      </span>
                      <span>Export File Pivot</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <b-table
                responsive
                striped
                hover
                :fields="fieldsBranch"
                :items="itemsBranch"
                :busy="isBranchBusy"
                show-empty
                empty-text="No matching records found"
                class="w-100"
                foot-clone
                no-footer-sorting
                @sort-changed="sortingTableBranch"
                :sort-by.sync="filterPromotionBranch.sortBy"
                no-local-sorting
                :sort-desc.sync="filterPromotionBranch.sortDesc"
              >
                <template #cell(total_revenue)="{ item }">
                  {{ item.total_revenue | numeral("0,0") }}
                </template>
                <template #cell(total_net_revenue)="{ item }">
                  {{ item.total_net_revenue | numeral("0,0") }}
                </template>
                <template v-slot:foot()="data">
                  <span
                    v-if="data.field.key == 'total_net_revenue'"
                    class="text-center"
                    >{{ subTotal["revenue"] | numeral("0,00") }}
                  </span>
                  <span v-else-if="data.field.key == 'total_revenue'">{{
                    subTotal["retail_sale"] | numeral("0,00")
                  }}</span>
                  <span
                    v-else-if="data.field.key == 'branchATV' && subTotal.atv"
                    >{{ subTotal.atv | numeral("0,0.00") }}fads</span
                  >
                  <span
                    v-else-if="data.field.key == 'branchUPT' && ubTotal.upt"
                    >{{ subTotal.upt | numeral("0,0.00") }}</span
                  >
                  <span v-else>
                    <div class="text-left px-2" v-if="data.field.text">
                      {{ data.field.text }}
                    </div>
                    <span v-else-if="data.field.id">{{
                      findPromotionTotal(data.field.id) | numeral("0,0.00")
                    }}</span>
                  </span>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleBranchChangeTake"
                @pagination="paginationBranch"
                :filter="filterPromotionBranch"
                :rows="rowsBranch"
                :limit="2"
              /> </b-card
          ></b-col>
        </b-row>
      </b-container>
    </div>

    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <FilterCustomDate
          customText="Transaction Date"
          id="12"
          :from="filter.TransactionDateFrom"
          :to="filter.TransactionDateTo"
          :type="filter.LastType"
          @update:type="
            (value) => {
              filter.LastType = value;
            }
          "
          @update:from="
            (value) => {
              filter.TransactionDateFrom = value;
            }
          "
          @update:to="
            (value) => {
              filter.TransactionDateTo = value;
            }
          "
        />

        <div class="my-3">
          <MultiSelect
            textFloat="Promotion"
            :options="options.promotions"
            textField="promotionName"
            valueField="id"
            placeholder="Select Promotion"
            v-model="filter.PromotionFilter"
            :value="filter.PromotionFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="tier"
            textFloat="Member tier"
            :options="options.memberTiers"
            textField="memberTierName"
            valueField="id"
            placeholder="Select Member tier"
            v-model="filter.MemberTierFilter"
            :value="filter.MemberTierFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="channel"
            textFloat="Channel"
            :options="options.channelTypes"
            textField="channelName"
            valueField="id"
            placeholder="Select Channel"
            v-model="filter.ChannelFilter"
            :value="filter.ChannelFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="product"
            textFloat="Barcode / Product"
            :options="options.product"
            textField="productName"
            valueField="id"
            placeholder="Select Barcode / Product"
            v-model="filter.ProductFilter"
            :value="filter.ProductFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="branch"
            textFloat="Purchase Branch"
            :options="options.branches"
            textField="branchName"
            valueField="id"
            placeholder="Select Purchase Branch"
            v-model="filter.BranchFilter"
            :value="filter.BranchFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="age"
            textFloat="Age Group"
            :options="options.ageGroups"
            textField="ageGroupName"
            valueField="id"
            placeholder="Select Age Group"
            v-model="filter.AgeGroupFilter"
            :value="filter.AgeGroupFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="gender"
            textFloat="Gender"
            :options="options.genderModels"
            textField="genderGroupName"
            valueField="id"
            placeholder="Select Gender"
            v-model="filter.GenderFilter"
            :value="filter.GenderFilter"
          />
        </div>
      </template>
    </SideBarFilter>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="formExport"
      @changeEmail="(val) => (formExport.email = val)"
      @submit="exportPromotionCustomer"
    />
  </div>
</template>

<script>
import Chart from "chart";
import ChartLoading from "@/components/loading/ChartLoading";

const moment = require("moment");

import ModalInputEmail from "@/components/customer/ModalInputEmail";

export default {
  components: { Chart, ModalInputEmail, ChartLoading },
  data() {
    return {
      isBusy: true,
      isBranchBusy: true,
      isChartLoading: true,
      fields: [
        {
          key: "promotion_name",
          text: "Total",
          label: "Promotion Name",
          class: "w-20",
          sortable: true,
        },
        {
          key: "promotion_use",
          label: "Number Of Use",
          class: "w-15",
          sortable: true,
        },
        {
          key: "promotion_discount",
          label: "Promotion Discount",
          class: "w-15",
          sortable: true,
        },
      ],
      fieldsBranch: [
        {
          key: "branch_name",
          label: "Branch",
          text: "Total",
          class: "mw-250",
          thClass: "position-sticky",
          stickyColumn: true,
        },
        {
          key: "total_revenue",
          label: "Retail Sales",
          class: "mw-250",
          sortable: true,
        },
        {
          key: "total_net_revenue",
          label: "Total Revenue",
          class: "mw-150",
          sortable: true,
        },
      ],
      stat: {
        percen_customer: 0,
        total_customer: 0,
        total_revenue: 0,
        total_user: 0,
      },
      statPromotion: {
        total_revenue: 0,
        total_discount: 0,
      },
      filter: {
        TransactionDateFrom: this.$store.state.filter.from,
        TransactionDateTo: this.$store.state.filter.to,
        Search: "",
        PromotionFilter: [],
        ChannelFilter: this.$store.state.filter.channel,
        BranchFilter: this.$store.state.filter.branch,
        AgeGroupFilter: this.$store.state.filter.age,
        GenderFilter: this.$store.state.filter.gender,
        ProductFilter: this.$store.state.filter.product,
        MemberTierFilter: this.$store.state.filter.tier,

        Page: 1,
        TypeDateFilter: 2,
        take: 10,
        Mode: 0,
        LastType: this.$store.state.filter.dateType,
      },

      filterTable: {
        page: 1,
        take: 10,
        search: "",
        SortColumnId: 0,
        SortType: true,
        sortBy: "",
        sortDesc: "",
      },
      statColumn: [
        {
          name: "Total Promotion Use",
          key: "total_use",
          isPercent: false,
          isDecimal: false,
        },

        {
          name: "Revenue From Promotion",
          key: "total_revenue",
          isPercent: false,
          isDecimal: false,
        },
      ],
      statLoading: true,
      filterPromotionBranch: {
        page: 1,
        take: 10,
        search: "",
        SortColumnId: 0,
        SortType: true,
        sortBy: "",
        sortDesc: "",
      },
      loading: true,
      loading2: true,
      items: [],
      itemsBranch: [],
      rows: 0,
      rowsBranch: 0,
      chartData: [],
      chartLabel: [],
      options: {
        promotions: [],
        memberTiers: [],
        channelTypes: [],
        branches: [],
        ageGroups: [],
        genderModels: [],
        dateType: [
          { text: "Last 7 Days", value: 3 },
          { text: "Last 30 Days", value: 2 },
          { text: "Last 365 Days", value: 1 },
          { text: "Custom", value: 0 },
        ],
        product: [],
      },
      formExport: {
        email: "",
      },
      subTotal: {
        total_customer: 0,
        percen_customer: 0,
        total_use: 0,
        total_revenue: 0,
      },
      promotionSubtotal: [],
    };
  },
  async created() {
    this.isLoading = true;
    this.getData();
    this.getOptions();
    this.isLoading = false;
  },
  computed: {
    handleDateRange() {
      if (this.filter.LastType == 0)
        return `${this.$moment(this.filter.TransactionDateFrom).format(
          "DD MMM YYYY"
        )} - ${this.$moment(this.filter.TransactionDateTo).format(
          "DD MMM YYYY"
        )}`;
      else return this.$displayFilterDate(this.filter.LastType);
    },
  },
  methods: {
    async getData() {
      // this.isLoading = true;
      // this.$bus.$emit("showLoading");
      this.getPromotion();
      this.getTotal();
      this.getPromotionBranch();
      const res = await this.$report.post(
        `/DashBoard/promotion/default`,
        this.filter
      );

      // return await Promise.all([
      this.getStat(res.data.detail.result_analysis);
      this.getPromotionChart(res.data.detail.result_graph);

      // this.isLoading = false;
      // this.$bus.$emit("hideLoading");
      // ]);
    },
    async getStat(data) {
      this.statLoading = true;

      var respData = data;
      if (!data) {
        const res = await this.$report.post(
          `/dashboard/promotion_analysis`,
          this.filter
        );
        respData = res.data.detail;
      }

      this.stat = respData;
      this.subTotal = respData;

      this.statLoading = false;
    },
    async getTotal() {
      let payLoad = { ...this.filter };

      const total = await this.$report.post(
        `/DashBoard/subtotal_promotion`,
        payLoad
      );
      if (total.data.result) this.promotionSubtotal = total.data.detail;
      else this.promotionSubtotal = [];
    },
    async getOptions() {
      const customFilter = await this.$report(`/RevenueReport/CustomFilter`);
      this.options.memberTiers = customFilter.data.memberTiers;
      this.options.channelTypes = customFilter.data.channelTypes;
      this.options.branches = customFilter.data.branches;
      this.options.ageGroups = customFilter.data.ageGroups;
      this.options.genderModels = customFilter.data.genderModels;
      this.options.product = customFilter.data.products
        ? customFilter.data.products.map((el) => {
            return {
              id: el.id,
              productName: `${el.barcode} / ${el.productName}`,
            };
          })
        : [];
      this.options.promotions = customFilter.data.promotions;
    },
    async getPromotionChart(data) {
      let payLoad = { ...this.filter };

      this.isChartLoading = true;
      var respData = await data;
      if (!data) {
        const res = await this.$report.post(
          `/dashboard/promotion_graph`,
          payLoad
        );
        respData = res.data.detail;
      }

      this.chartLabel = await respData[0].data.map((el) => el.name);

      var data = [
        {
          label: "Usage",
          type: "bar",
          data: await respData[0].data.map((el) => el.value),
          backgroundColor: await this.$store.dispatch("getChartColor", 0),
        },
      ];

      this.chartData = data;
      this.isChartLoading = false;
    },
    async getPromotion() {
      this.isBusy = true;
      let filter = { ...this.filter };

      let body = {
        customFilterRequest: filter,
        PageNumber: this.filterTable.page,
        RecordPerPage: this.filterTable.take,
        SortColumnId: this.filterTable.SortColumnId,
        SortType: this.filterTable.SortType,
      };
      const res = await this.$report.post(`/DashBoard/promotion_table`, body);
      if (res.data.result == 0) {
        this.items = [];
        this.rows = 0;
        this.isBusy = false;
        this.statPromotion.total_revenue = 0;
      } else {
        this.items = res.data.detail.data;
        this.rows = res.data.detail.total;
        this.isBusy = false;
        this.statPromotion.total_revenue = res.data.detail.total_revenue;
        this.statPromotion.total_discount = res.data.detail.total_discount;
      }
    },
    async getPromotionBranch() {
      this.isBranchBusy = true;
      let filter = { ...this.filter };

      let body = {
        customFilterRequest: filter,
        PageNumber: this.filterPromotionBranch.page,
        RecordPerPage: this.filterPromotionBranch.take,
        SortColumnId: this.filterPromotionBranch.SortColumnId,
        SortType: this.filterPromotionBranch.SortType,
      };
      const res = await this.$report.post(`/dashboard/promotion_branch`, body);

      if (res.data.detail.data.length) {
        await res.data.detail.data[0].list_promotion.forEach((element) => {
          this.fieldsBranch.push({
            key: `promotion_id_${element.promotion_id}`,
            label: element.promotion_name,
            class: "mw-150",
            id: element.promotion_id,
          });
        });
        this.itemsBranch = res.data.detail.data;

        this.itemsBranch.forEach(async (item1) => {
          await item1.list_promotion.forEach((item2) => {
            item1[`promotion_id_${item2.promotion_id}`] = ` ${
              item2.promotion_discount
                ? item2.promotion_discount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : (0).toFixed(2)
            }`;
          });
        });
      } else {
        (this.fieldsBranch = [
          {
            key: "branch_name",
            label: "Branch",
            class: "mw-250",
            sortable: true,
            stickyColumn: true,
          },
          {
            key: "total_revenue",
            label: "Retail Sales",
            class: "mw-250",
            sortable: true,
          },
          {
            key: "total_net_revenue",
            label: "Total Revenue",
            class: "mw-150",
            sortable: true,
          },
        ]),
          (this.itemsBranch = []);
      }

      this.rowsBranch = res.data.detail.total;
      this.isBranchBusy = false;
    },

    pagination(val) {
      this.filterTable.page = val;

      this.getPromotion();
    },
    paginationBranch(val) {
      this.filterPromotionBranch.page = val;

      this.getPromotionBranch();
    },
    handleChangeTake(val) {
      this.filterTable.page = 1;

      this.filterTable.take = val;
      this.getPromotion();
    },
    handleBranchChangeTake(val) {
      this.filterPromotionBranch.page = 1;

      this.filterPromotionBranch.take = val;
      this.getPromotionBranch();
    },
    async exportExcel(report_type, name) {
      try {
        let filter = { ...this.filter };

        let body = {
          customFilterRequest: filter,
          report_type: report_type,
        };
        this.$bus.$emit("showLoading");

        const res = await this.$report.post(
          `/dashboard/promotion_report`,
          body,
          {
            responseType: "blob",
          }
        );

        let data = res;

        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$bus.$emit("hideLoading");
      } catch (error) {
        console.error(error);
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    async exportPromotionCustomer() {
      try {
        let filter = { ...this.filter };

        let body = {
          customFilterRequest: {
            ...filter,
          },
          report_type: 1,
        };

        this.$bus.$emit("showLoading");

        const res = await this.$report.post(
          `/DashBoard/Export/promotion_customer`,
          body
        );

        if (res.data.result)
          this.confirmAlert({
            message: "Do you want to download the file now?",
            title: "Success !",
            icon: "success",
            confirmButtonText: "Go To Page",
            cancelButtonText: "Close",
          }).then((val) => {
            if (val.isConfirmed) {
              const routeData = this.$router.resolve({
                name: "Customer Report List",
              });
              window.open(routeData.href, "_blank");
            }
          });
        else this.errorAlert(res.data.message);
        this.$bus.$emit("hideLoading");
      } catch (error) {
        console.error(error);
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    filterActions() {
      this.checkFilterDate();
      this.getData();
    },
    clearFilter() {
      this.filter = {
        TransactionDateFrom: moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD"),
        TransactionDateTo: moment().endOf("month").format("YYYY-MM-DD"),
        Search: "",
        PromotionFilter: [],
        MemberTierFilter: [],
        ChannelFilter: [],
        BranchFilter: [],
        AgeGroupFilter: [],
        GenderFilter: [],
        ProductFilter: [],
        Page: 1,
        TypeDateFilter: 2,
        take: 10,
        Mode: 0,

        LastType: 2,
      };

      this.filterActions();
      this.$store.dispatch("clearFilter");
      this.$refs.sidebarFilter.hide();
    },

    handleSearch() {
      this.getPromotionBranch();
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    filterGraph(val) {
      this.checkFilterDate();
      this.filter.TypeDateFilter = val;
      this.getPromotionChart();
    },
    checkFilterDate() {
      if (this.filter.LastType == 1) {
        // this.filter.LastType = 7;
        this.filter.TransactionDateFrom = moment()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = moment().format("YYYY-MM-DD");
      } else if (this.filter.LastType == 2) {
        // this.filter.LastType = 30;
        this.filter.TransactionDateFrom = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = moment().format("YYYY-MM-DD");
      } else if (this.filter.LastType == 3) {
        // this.filter.LastType = 365;
        this.filter.TransactionDateFrom = moment()
          .subtract(1, "years")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = moment().format("YYYY-MM-DD");
      } else {
        // this.filter.LastType = 0;
        this.filter.TransactionDateFrom = moment(
          this.filter.TransactionDateFrom
        ).format("YYYY-MM-DD");
        this.filter.TransactionDateTo = moment(
          this.filter.TransactionDateTo
        ).format("YYYY-MM-DD");
      }
    },
    openModalEmail() {
      this.formExport.email = "";
      this.$refs.ModalInputEmail.show();
    },
    sortingTable(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filterTable.SortType &&
        !ctx.sortDesc &&
        this.filterTable.SortColumnId == index
      ) {
        this.filterTable.SortColumnId = 0;
        this.filterTable.SortColumnId = 0;
        this.filterTable.sortBy = "";
        this.filterTable.sortDesc = "";
      } else {
        this.filterTable.SortColumnId = index;
        this.filterTable.SortType = ctx.sortDesc;
      }
      this.getPromotion();
    },
    sortingTableBranch(ctx) {
      let index = this.fieldsBranch.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filterPromotionBranch.SortType &&
        !ctx.sortDesc &&
        this.filterPromotionBranch.SortColumnId == index
      ) {
        this.filterPromotionBranch.SortColumnId = 0;
        this.filterPromotionBranch.SortColumnId = 0;
        this.filterPromotionBranch.sortBy = "";
        this.filterPromotionBranch.sortDesc = "";
      } else {
        this.filterPromotionBranch.SortColumnId = index;
        this.filterPromotionBranch.SortType = ctx.sortDesc;
      }
      this.getPromotionBranch();
    },
    changeStartDate() {
      if (this.filter.TransactionDateFrom && !this.filter.TransactionDateTo) {
        this.filter.TransactionDateTo = this.filter.TransactionDateFrom;
      }
      if (
        this.filter.TransactionDateFrom &&
        this.filter.TransactionDateFrom >= this.filter.TransactionDateTo
      ) {
        this.filter.TransactionDateTo = this.$moment(
          this.filter.TransactionDateFrom
        )
          .add("day", 1)
          .format();
      }
    },
    findPromotionTotal(id) {
      let find = this.promotionSubtotal.find((el) => el.promotion_id == id);

      if (find) return find.promotion_discount;
      return 0;
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
  opacity: 1 !important;
}
.text-filter {
  opacity: 0.5;
}
.w-40 {
  width: 40%;
}
.w-65 {
  width: 65%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-auto {
  width: auto;
}
.mw-250 {
  min-width: 250px !important;
}
.mw-150 {
  min-width: 150px !important;
}
.position-sticky {
  position: sticky !important;
  background-color: var(--theme-secondary-color) !important;
  color: var(--font-color) !important;
}
</style>
